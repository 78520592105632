import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {main_cat_list: { in: "Skydd och stöd" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "skydd-och-stöd"
    }}>{`Skydd och stöd`}</h1>
    <h2><Link to="/skydd-och-stod/handledsstod/" mdxType="Link">Handledsstöd</Link></h2>
    <p>{`Handledsstöd är avgörande för att ge dina handleder det stöd och skydd de behöver under träning, från tunga lyft till intensiva pass. Dessa produkter är designade för att minska risken för skador, förbättra din prestanda och öka komforten. Välj bland en mängd olika handledsstöd i högkvalitativa material som neopren, läder och elastan – alla utvecklade för att ge optimal stabilitet, värme och enkel justering. Oavsett om du är ny på gymmet eller en erfaren atlet, hittar du det perfekta handledsstödet för dina behov hos oss.`}</p>
    <h2><Link to="/skydd-och-stod/knaskydd/" mdxType="Link">Knäskydd</Link></h2>
Hos oss hittar du ett brett utbud av knäskydd som säkerställer både komfort och funktion under alla typer av fysisk aktivitet. Knäskydd är ett oumbärligt träningsredskap för dig som vill förebygga skador, reducera smärta, samt förbättra stabilitet och prestation. Våra högkvalitativa produkter är tillverkade med de bästa materialen för att garantera hållbarhet och långvarig användning, vilket gör dem idealiska för både nybörjare och erfarna atleter.
    <h2><Link to="/skydd-och-stod/armbagsskydd/" mdxType="Link">Armbågsskydd</Link></h2>
    <p>{`När du utför styrketräning eller tunga lyft är det kritiskt att ha rätt skydd för att undvika skador och maximera din prestation. Armbågsskydd erbjuder det stöd och den stabilitet du behöver för att pressa dina gränser tryggt. Med egenskaper som förbättrad blodcirkulation och komfortabel passform blir dessa skydd snabbt en oumbärlig del av din träningsutrustning. De är designade med justerbara funktioner som kardborreband för att säkerställa en perfekt passform, vilket gör dem både praktiska och effektiva. Utforska vårt utbud av armbågsskydd och skydda dig själv för att kunna träna säkrare och mer effektivt.`}</p>
    <h2><Link to="/skydd-och-stod/handledslindor/" mdxType="Link">Handledslindor</Link></h2>
Handledslindor är oumbärliga för att erbjuda stöd och stabilitet för handlederna under intensiva träningspass och tyngdlyftning. Dessa skyddsprodukter minimerar risken för skador och hjälper dig att öka din prestation genom att reducera smärta och obehag. Perfekt för allt från nybörjare till elitidrottare, handledslindor säkerställer att handlederna hålls i rätt position, vilket förebygger vrickningar och överbelastningar. Välj rätt kombination av material, längd och elasticitet för att uppnå optimalt stöd och komfort under din träning. Utforska vårt sortiment för att hitta den perfekta lösningen för dina behov och träningsmål.
    <h2><Link to="/skydd-och-stod/knalindor/" mdxType="Link">Knälindor</Link></h2>
    <p>{`Knälindor är en oumbärlig del av din träningsutrustning när du vill ha extra stöd och stabilitet för knäna under tunga lyft och intensiva träningspass. De hjälper till att stabilisera knäleden, vilket minskar risken för skador och gör att du kan träna med större självförtroende. Knälindor är tillverkade av hållbara och flexibla material som polyester och elastan, och erbjuder justerbar passform för optimal komfort och stöd. Perfekta för att maximera din prestation genom att låta dig lyfta tyngre och göra fler repetitioner, och de finns i olika färgkombinationer så att du kan matcha din övriga utrustning.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      